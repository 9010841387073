import { FormDropDownProps } from "@components/form/FormDropDown";

/**
 * @fileoverview constants for orderTest component
 * */
export const tnmCategoryTOptions = [
  { value: "T1", label: "T1" },
  { value: "Tis", label: "Tis" },
  { value: "T1a", label: "T1a" },
  { value: "T1b", label: "T1b" },
  { value: "T1c", label: "T1c" },
  { value: "T2", label: "T2" },
  { value: "T3", label: "T3" },
  { value: "T4", label: "T4" },
  { value: "T4a", label: "T4a" },
  { value: "T4b", label: "T4b" },
  { value: "T4c", label: "T4c" },
  { value: "T4d", label: "T4d" },
  { value: "T0", label: "T0" },
  { value: "TX", label: "TX" }
];

const formCategoryTDropDown: FormDropDownProps = {
  name: "patient.staging.tnmCategoryT",
  label: "TNM category T (tumor size)",
  options: tnmCategoryTOptions,
  required: true
};

const tnmCategoryNOptions = [
  { value: "N0", label: "N0" },
  { value: "N1", label: "N1" },
  { value: "N1mi", label: "N1mi" },
  { value: "N2", label: "N2" },
  { value: "N2a", label: "N2a" },
  { value: "N2b", label: "N2b" },
  { value: "N3", label: "N3" },
  { value: "N3a", label: "N3a" },
  { value: "N3b", label: "N3b" },
  { value: "N3c", label: "N3c" },
  { value: "NX", label: "NX" }
];

const formCategoryNDropDown: FormDropDownProps = {
  name: "patient.staging.tnmCategoryN",
  label: "TNM category N (tumor size)",
  options: tnmCategoryNOptions,
  required: true
};

const menopausalStatusOptions = [
  { value: "Premenopausal", label: "Premenopausal" },
  { value: "Postmenopausal", label: "Postmenopausal" },
  { value: "Unknown", label: "Unknown" }
];

const formCategoryMSDropDown: FormDropDownProps = {
  name: "patient.menopause.status",
  label: "Menopausal status indication",
  options: menopausalStatusOptions,
  required: true
};

export { formCategoryTDropDown, formCategoryNDropDown, formCategoryMSDropDown };
