import { Box, Button, Grid, Typography } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { StandardTestForm } from "../../utils/interface/order";

/**
 * @fileoverview This file contains the UploadFile Component for uploading of /Histology files in the OrderTest page.
 */

//type FileUploadCategory = "" | "Histology";
type FileUploadCategory = "Histology";
/*** UploadFile Props Type Declaration **/
interface UploadFileProps {
  // Category of the file to be uploaded.
  category: FileUploadCategory;
}

/**
 * UploadFile Component.
 * Displays button for uploading of /Histology files. Shows the names of the files uploaded.
 *
 * */
const UploadFile = (props: UploadFileProps) => {
  const { control } = useFormContext<StandardTestForm>();
  const [fileNames, setFileNames] = useState<string[]>([]);

  /** Handles Logic for uploading of /Histology files in the OrderTest page. **/
  const updateFilesHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setFileNames([]);
    if (!e.target.files) return;
    for (let i = 0; i < e.target.files.length; i++) {
      const newFileName = e.target.files[i].name;
      setFileNames((_fileNames: string[]) => [..._fileNames, newFileName]);
    }
  };
  //const title = props.category === "" ? " File Upload" : "Histology File Upload";
  //const controllerName = props.category === "" ? "orderFiles" : "orderFilesHistology";
  const title = "Histology File Upload";
  const controllerName = "orderFilesHistology";

  return (
    <Box sx={{ maxWidth: "100%", overflow: "auto" }}>
      <Typography variant="h6" sx={{ mt: 2 }}>
        {title}
      </Typography>
      <Grid container className="report-form-input" direction="column">
        <Grid item>
          <Controller
            name={controllerName}
            control={control}
            render={({ field: { ref, name, onChange } }) => (
              <>
                <input
                  hidden
                  ref={ref}
                  name={name}
                  type="file"
                  multiple
                  accept="image/*, .svs"
                  id={"button-file" + props.category}
                  onChange={(e) => {
                    updateFilesHandler(e);
                    onChange(e.target.files);
                  }}
                />
                <label htmlFor={"button-file" + props.category}>
                  <Button variant="outlined" component="span" sx={{ mb: 2 }}>
                    Upload Files
                  </Button>
                </label>
              </>
            )}
          />
        </Grid>
        <Grid item>
          <Box className="text-truncate text-muted">
            {fileNames.map((filename) => (
              <Typography key={filename} variant="body2">
                {filename}
              </Typography>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UploadFile;
