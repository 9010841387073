import CancerFoci from "@components/orderTest/CancerFoci";
import ClinicalStaging from "@components/orderTest/ClinicalStaging";
import MenopausalStatus from "@components/orderTest/MenopausalStatus";
import Demographics from "@components/orderTest/Demographics";
import RealWorldExamples from "@components/orderTest/orderTest3/RealWorldExamples";
import WithOrderFormLayOut from "@hoc/withOrderFormLayOut";
import { useCurrentUserData } from "@hooks/user";
import { Send } from "@mui/icons-material";
import { Box, Button, CircularProgress, Grid, LinearProgress, Typography } from "@mui/material";
import { green } from "@mui/material/colors";
import { addLabTestOrder } from "@services/orderService";
import { useAuth } from "@store/auth/model";
import { LabTestForm } from "@utils/interface/order";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import LabTestInstruction from "./LabTestInstruction";

const LabTest = () => {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [openInstruction, setOpenInstruction] = useState(false);

  const user = useCurrentUserData();
  const methods = useForm<LabTestForm>({
    defaultValues: {
      patient: {
        foci: {
          focusSize: 0,
          estrogen: "",
          progesterone: "",
          her2: "",
          ki67: 0,
          histology: { grade: "", subtypes: [] }
        }
      }
    }
  });

  console.log(methods.watch());

  const auth = useAuth();

  const onSubmit = async (formData: LabTestForm) => {
    setLoading(true);
    if (!auth) {
      alert("Current user not found");
      return;
    }
    const physician = user.lastname + ", " + user.firstname;

    setSubmitting(true);

    await addLabTestOrder(formData, auth, physician);

    setLoading(false);
    setSubmitting(false);
    setOpenInstruction(true);
  };

  const { setValue } = methods;

  if (openInstruction) return <LabTestInstruction />;

  return (
    <>
      <Grid id="fill-test-form" item container>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Demographics />
            <ClinicalStaging />
            <MenopausalStatus />
            <CancerFoci methods={methods} />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ m: 1, position: "relative" }}>
                <Button
                  id="test-order-submit"
                  type="submit"
                  size="large"
                  value="Generate Report"
                  variant="contained"
                  color="secondary"
                  disabled={loading}
                  endIcon={<Send />}
                >
                  Submit test order
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px"
                    }}
                  />
                )}
              </Box>
              {submitting && (
                <Box className="mt-1" sx={{ flexGrow: 1 }}>
                  <Typography variant="h6" color="text.secondary">
                    Submitting order...
                  </Typography>
                  <LinearProgress />
                </Box>
              )}
            </Box>
          </form>
        </FormProvider>
      </Grid>
      <Grid item lg={6}>
        <RealWorldExamples setValue={setValue} />
      </Grid>
    </>
  );
};

export default WithOrderFormLayOut(LabTest);
