import OrderRepository, { Order } from "@/repository/order";
import { Patient, PatientRepository } from "@/repository/patient";
import { AtaraxisUser, incrementOrderedTestsNumber, UserNotFoundException } from "@repository/user";
import { analytics } from "@config/firebase";
import { LabTestForm, StandardTestForm } from "@utils/interface/order";
import axios from "axios";
import { logEvent } from "firebase/analytics";
import { User } from "firebase/auth";

const addDigitalTestOrder = async (
  orderFormData: StandardTestForm,
  authUser: User,
  physician: string,
  //numberOfMRIFiles: number,
  numberOfHistologyFiles: number
): Promise<string[]> => {
  const patient = new Patient(
    "",
    authUser.uid,
    orderFormData.patient.demography,
    orderFormData.patient.staging,
    orderFormData.patient.menopause,
    orderFormData.patient.foci,
    []
  );
  const patientId = await PatientRepository.addPatientEntry(patient);
  const order = new Order(
    authUser.uid,
    patientId,
    physician,
    "In Progress",
    //numberOfMRIFiles,
    numberOfHistologyFiles,
    "Digital",
    0
  );
  const orderId = await OrderRepository.addOrderEntry(order);
  await PatientRepository.updatePatientOrdersField(patientId, orderId);

  await incrementOrderedTestsNumber(authUser.uid);

  logEvent(analytics, "addDigitalTestOrder");

  return [orderId, patientId];
};

const addLabTestOrder = async (formData: LabTestForm, authUser: User, physician: string): Promise<void> => {
  const patient = new Patient(
    "",
    authUser.uid,
    formData.patient.demography,
    formData.patient.staging,
    formData.patient.menopause,
    formData.patient.foci,
    []
  );
  const patientId = await PatientRepository.addPatientEntry(patient);
  const order = new Order(authUser.uid, patientId, physician, "In Progress", 0, "Lab", 0);
  const orderId = await OrderRepository.addOrderEntry(order);
  await PatientRepository.updatePatientOrdersField(patientId, orderId);

  logEvent(analytics, "addLabTestOrder");
};

const generateReport = async (patientId: string): Promise<void> => {
  const res = await axios.post(
    `https://mmmmmaasd--inference-modal-connection-inference-on-patient.modal.run?patient_id=${encodeURIComponent(
      patientId
    )}`
  );
  console.log(res);
};

export { addDigitalTestOrder, addLabTestOrder, generateReport };
